/* #################################### PROGRAMS 1 ############################## */
.section__vwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
  background-color: rgb(231, 168, 168);
}

.vprograms {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

.img__vwrapper img {
  width: 500px;
}

  
  /*#################################### PROGRAMS 2 ############################## */
  .section__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;
    /* background-color: var(--light-bk); */
}

.programs {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
}

.img__wrapper img {
    width: 500px;
}



@media screen and (max-width: 345px) {
  .section__wrapper {
    height: 90%;
  }

  .programs {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }

  .img__wrapper img {
    width: 300px;
}

  /*#################################### PROGRAMS 2 ############################## */
  .section__vwrapper {
    height: 90%;
  }

  .vprograms {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }

  .img__vwrapper img {
    width: 300px;
  }
}



@media screen and (min-width: 350px) and (max-width: 390px) {
  .section__wrapper {
    height: 90%;
  }

  .programs {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }

  .img__wrapper img {
    width: 350px;
}

  /*#################################### PROGRAMS 2 ############################## */
  .section__vwrapper {
    height: 90%;
  }

  .vprograms {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }

  .img__vwrapper img {
    width: 350px;
  }
}



@media screen and (min-width: 395px) and (max-width: 430px) {
    .section__wrapper {
        height: 90%;
      }

      .programs {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
      }

      .img__wrapper img {
        width: 400px;
    }

      /*#################################### PROGRAMS 2 ############################## */
      .section__vwrapper {
        height: 90%;
      }

      .vprograms {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
      }

      .img__vwrapper img {
        width: 400px;
    }
}


@media screen and (min-width: 540px) and (max-width: 599px)  {
    .section__wrapper {
        height: 100%;
      }

      .programs {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
      }

      /*#################################### PROGRAMS 2 ############################## */
      .section__vwrapper {
        height: 100%;
      }

      .vprograms {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
      }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
    .section__wrapper {
        height: 95%;
      }

      .programs {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
      }

      /*#################################### PROGRAMS 2 ############################## */
      .section__vwrapper {
        height: 95%;
      }

      .vprograms {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
      }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .section__wrapper {
        height: 100%;
      }

      .programs {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
      }

      .img__wrapper img {
        width: 680px;
    }

      /*#################################### PROGRAMS 2 ############################## */
      .section__vwrapper {
        height: 100%;
      }

      .vprograms {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
      }

      .img__vwrapper img {
        width: 680px;
    }
}