.contact iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.contact .shadow {
    height: 105%;
    margin-bottom: 10rem;
}

.contact .shadow:hover {
    height: 110%;
}

.contact .right {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.contact .right h2 {
    margin-bottom: 1rem;
    font-size: 1.3rem;
    font-weight: 900;
    color: var(--red);
}

.contact p {
    margin: 5px 0;
    font-size: 0.9rem;
}

.contact .grid {
    grid-template-columns: repeat(3, 1fr);
}

.contact .box h4 {
    font-size: 16px;
    color: var(--red);
    margin-top: 10px;
}

.contact .box p {
    font-size: 13px;
    margin-top: 10px;
}

.contact-form_header h4{
    font-size: 16px !important;
    color: var(--red);
}

.contact textarea,
.contact  input {
    width: 90%;
    padding: 3px;
    margin-bottom: 5px;
    outline: none;
    border: 1px solid grey;
}

.contact .flexSB input:nth-child(1) {
    margin-right: 10px;
}

.contact h3 {
    margin-top: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.contact span {
    color: #1eb2a6;
    font-weight: 600;
    font-size: 15px;
    word-spacing: 5px;
}

.flexSBB label {
    display: grid;
    grid-template-columns: repeat(2);
}

.buton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btttn {
    padding: 0.5rem;
    background-color: var(--red);
    color: white;
    border: none;
    transition: var(--transition);
    margin-bottom: 0.8rem;
    border-radius: 3px;
}

.btttn:hover {
    cursor: pointer;
    background-color: black;
    transition: var(--transition);
}

.contact__socials h3 {
    color: black !important;
    font-size: 1.3rem;
}

.contact__socials {
    margin-top: 2rem !important;
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  .contact__socials a {
    background: var(--red);
    padding: 0.5rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
  }
  
  .contact__socials a svg {
    color: var(--color-gray-500);
  }
  
  .contact__socials a:hover {
    border-color: var(--color-gray-100);
    background: rgb(187, 181, 181);
  }






@media screen and (min-width: 768px) and (max-width: 1024px) {
    .contact {
        height: 180%;
        margin-top: 2rem;
        margin-bottom: 20px;
    }
    
    .contact .container {
        flex-direction: column;
    }

    .contact iframe {
        height: 50vh;
    }

    .contact .grid {
        grid-template-columns: 1fr;
    }

    .contact .left {
        width: 100%;
    }

    .contact .right {
        width: 90%;
    }

    .contact textarea {
        width: 90%;
    }

    .btttn {
        position: relative;
        margin-top: 15px;
        left: -2rem;
    }
}

@media screen and (min-width: 992px) {
    .contact textarea {
        width: 100%;
    }
}





/* MEDIA QUERIES (small screens)  */
@media screen and (max-width: 425px) {

    .contact .left {
        display: none;
    }

    .contact iframe {
        display: none;
    }


}


@media screen and (max-width: 600px) {
    .contact {
        height: 155%;
        margin-top: 2rem;
        margin-bottom: 20px;
    }

    .contact .left {
        display: none;
    }

    .contact iframe {
        display: none;
    }
    
    
    .padding {
        padding: 40px 0 !important;
    }
    .contact .right {
        width: 90%;
    }
    

    .contact .grid {
        grid-template-columns: 1fr;
        grid-gap: 0.5rem;
    }

    .contact h2 {
        font-size: 1.3rem;
    }

    .fbutton .btn.lg {
        padding: 1rem 1rem;
    }

    .btttn {
        position: relative;
        margin-top: 15px;
        left: -2rem;
    }

    .shadow {
        margin-bottom: 0;
    }
}


@media screen and (min-width: 600px) and (max-width: 767px) {
    .contact {
        height: 155%;
        margin-top: 2rem;
        margin-bottom: 20px;
    }

    .contact iframe {
        display: none;
    }
    

    .contact .left {
        display: none;
    }

    .padding {
        padding: 40px 0 !important;
    }

    .contact .right {
        width: 90%;
    }
    

    .contact .grid {
        grid-template-columns: 1fr;
        grid-gap: 0.5rem;
    }

    .contact h2 {
        font-size: 1.3rem;
    }

    .fbutton .btn.lg {
        padding: 1rem 1rem;
    }

    .btttn {
        position: relative;
        margin-top: 15px;
        left: -2rem;
    }

    .shadow {
        margin-bottom: 0;
    }
}



@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .contact textarea {
        width: 90%;
    }

    .contact .right {
        padding-top: 10px;
        padding-left: 20px;
        padding-right: 10px;
    }

    form label {
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .btttn {
        margin-top: 5px;
    }
}