h1,
h2,
h3,
h4 {
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

.section {
    background: var(--white);
    width: 100vw;
    height: 45vh;
    margin: 2rem auto;
    max-width: 1170px;
  }




  @media screen and (min-width: 992px) {
    .section {
      width: 95vw;
    }
  }

  .icon {
    font-size: 3rem;
    color: var(--color-gray-400);
  }

  .section-center {
    margin: 0 auto;
    margin-top: 3rem;
    width: 80vw;
    height: 450px;
    max-width: 700px;
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;
  }

  article {
    position: absolute;
    top: 0;
    left: 20%;
    width: 60%;
    height: 80%;
    opacity: 0;
    transition: all 0.3s linear;
  }

  article.activeSlide {
    opacity: 1;
    transform: translateX(0);
  }
  article.lastSlide {
    transform: translateX(-100%);
  }
  article.nextSlide {
    transform: translateX(100%);
  }

  .article__wrapper img {
    position: relative;
  }

  .person-img {
    border-radius: 5px;
    margin-bottom: 1rem;
    width: 55%;
    height: auto;
    left: 23%;
    object-fit: cover;
    /*border: 4px solid hsl(210, 31%, 80%);*/
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  article h4 {
    text-transform: uppercase;
    color: var(--color-gray-400);
    margin-bottom: 0.25rem;
  }
  .main__title {
    padding-top: 1rem;
    color: var(--red);
  }
  .title {
    text-transform: capitalize;
    margin-bottom: 0.75rem;
    color: hsl(209, 34%, 30%);
  }

  .title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .title h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }

  .prev,
.next {
  position: absolute;
  top: 100px;
  transform: translateY(-50%);
  background: var(--red);
  color: #fff;
  width: 1.25rem;
  height: 1.2rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s linear;
}
.prev:hover,
.next:hover {
  background: var(--black);
}
.prev {
  left: 0;
}
.next {
  right: 0;
}

.article__source {
  color: var(--black);
  font-size: 1.3rem;
}

.article__source:hover {
  cursor: pointer;
  color: red;
}

@media (min-width: 800px) {
  .section {
    height: 38vh;
  }
    .text {
      max-width: 45rem;
    }
    .prev,
    .next {
      width: 2rem;
      height: 2rem;
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 1024px) {
    .section {
        height: 30vh;
    }
  }


  @media screen and (max-width: 600px) {
    .section {
        height: 30vh;
        margin-top: 5rem;
    }

    .section-center {
        margin-top: 3rem;
        height: 180px;
      }

      .prev,
      .next {
        position: absolute;
        top: 60px;
      }

      .person-img {
        left: 20%;
      }
  }