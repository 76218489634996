Header {
    text-align: left;
}

Header h1 {
    position: relative;
    text-align: left;
    color: var(--light-bg);
}

Header p {
    position: relative;
    font-weight: 400;
    font-size: 1.2rem;
    text-align: left;
    left: -5rem;
}

.blog__section-image img{
    height: 80%;
    width: 80%;
    position: relative;
    left: 8rem;
    margin-bottom: 2rem;
}

.blog__section-content h2, h3, h4 {
    color: var(--red);
    margin-top: 2rem;
}

.highlight-H {
    font-weight: bold;
    font-size: 1.2rem;
}

.highlight {
    font-weight: bold;
    font-style: italic;
}

.reference>a {
    text-decoration: blue;
}


/* MEDIA QUERIES (medium screens)  */
@media screen and (max-width: 1024px) {
    .blog__section-image img {
        height: auto;
        left: 5rem;
    }
}




/* MEDIA QUERIES (small screens)  */
@media screen and (max-width: 600px) {    
    .blog__section-image img {
        height: auto;
        left: 2rem;
    }

    .highlight-H {
        font-size: 1rem;
    }

    .reff {
        font-size: 0.9rem;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
}

