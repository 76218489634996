
.main__header {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 105vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}

.main__header-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;
}

.myVideo Video {
  position: absolute;
  width: 100%;
  top: 5rem;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

.content {
  position: relative;
  width: 90%;
  top: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  color: var(--white);
  z-index: 1;
}

.main__header-container .content h1{
  text-align: center;
  left: 0;
  font-size: 2.5rem;
  font-weight: 900;
  color: var(--red);
}

.main__header-container .content span {
  font-weight: 300;
  font-size: 1.6rem;
  font-style: italic;
  color: var(--white);
}

.content .bttn__card {
  margin-top: 2rem;
  text-transform: none;
}

.content .bttn__card span {
  margin-top: 2rem;
  text-transform: none;
}


/*#################################### Blog  ############################## */
.blog {
  background-color: rgb(231, 168, 168);
}

.shadow {
  margin-bottom: 1rem;
  height: 95%;
}

.shadow:hover {
  height: 97%;
  transition: var(--transition);
}







/*#################################### Footer  ############################## */
footer {
  background: var(--white);
  margin-top: 1rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  font-size: 0.9rem;
  color: var(--color-gray-500);
}

.footer__container {
  display: grid;
  grid-template-columns: 36rem 1fr;
  gap: 15rem;
  border-top: 4px solid var(--black);
}

.footer__container section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.7rem;
}

.footer__container section p {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.footer__container section .lnk {
  color: var(--color-gray-500);
  text-transform: none;
  font-family: 'Roboto', sans-serif;
}

.footer__container section .lnk:hover {
  color: rgb(232, 101, 101);
}

.footer__container section h4 {
  margin-bottom: 0.6rem;
  font-size: 1.2rem;
  color: var(--red);
  text-transform: uppercase;
}

.footer__socials {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.footer__socials a {
  background: var(--red);
  padding: 0.7rem;
  border: 1px solid transparent;
  border-radius: 0.6rem;
  transition: var(--transition);
}

.footer__socials a svg {
  color: var(--color-gray-500);
}

.footer__socials a:hover {
  border-color: var(--color-gray-100);
  background: rgb(187, 181, 181);
}

.footer__copyright {
  background: var(--white);
  color: var(--color-gray-500);
  text-align: center;
  padding: 1.5rem 0;
  border-top: 4px solid var(--black);
  margin-top: 5rem;
}

.footer__copyright small {
  font-size: 1.3rem;
}



/* MEDIA QUERIES (small screens)  */
@media screen and (max-width: 430px) {
    /* MAIN HEADER */
.main__header-container .content {
  text-align: center;
  left: -3rem;
  top: 16rem;
}

.main__header-container .content h1 {
  font-size: 1.5rem;
}

.main__header-container .content span {
  font-size: 1rem;
  width: 80%;
}

/* MAIN HEADER ENDS */


/* BLOG */
.shadow {
  margin-bottom: 1rem;
}



/* FOOTER */
footer {
  margin-top: 1rem;
}

.footer__container {
  grid-template-columns: 1fr;
  gap: 1rem;
}

.footer__container section {
  margin-top: 3rem;
  align-items: center;
}

.footer__container section p {
  text-align: center;
}

.footer__copyright small {
  font-size: 1rem;
}


}





@media screen and (min-width: 540px) and (max-width: 599px)  {
  /* MAIN HEADER */
.main__header-container .content {
  text-align: center;
  left: -3rem;
  top: 16rem;
}

.main__header-container .content h1 {
  font-size: 1.5rem;
}

.main__header-container .content span {
  font-size: 1rem;
  width: 80%;
}

/* MAIN HEADER ENDS */



/* BLOG */
.shadow {
  margin-bottom: 1rem;
}



/* FOOTER */
footer {
  margin-top: 1rem;
}

.footer__container {
  grid-template-columns: 1fr;
  gap: 1rem;
}

.footer__container section {
  margin-top: 3rem;
  align-items: center;
}

.footer__container section p {
  text-align: center;
}

.footer__copyright small {
  font-size: 1rem;
}

}




@media screen and (min-width: 600px) and (max-width: 767px) {
    /* MAIN HEADER */
.main__header-container .content {
  text-align: center;
  left: -3rem;
  top: 16rem;
}

.main__header-container .content h1 {
  font-size: 1.5rem;
}

.main__header-container .content span {
  font-size: 1rem;
  width: 80%;
}

/* MAIN HEADER ENDS */



/* BLOG */
.shadow {
  margin-bottom: 1rem;
}



/* FOOTER */
footer {
  margin-top: 1rem;
}

.footer__container {
  grid-template-columns: 1fr;
  gap: 1rem;
}

.footer__container section {
  margin-top: 3rem;
  align-items: center;
}

.footer__container section p {
  text-align: center;
}

.footer__copyright small {
  font-size: 1rem;
}

}




/* MEDIA QUERIES (medium screens)  */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  /*MAIN HEADER*/
  .main__header {
    padding: 12rem 0;
  }
  
  .main__header-container {
    gap: 1rem;
  }
  
  video {
    width: 100%;
    height: 85%;
  }
  
  .content {
    left: 0;
    top: 5.5rem;
  }

    /* MAIN HEADER ENDS */
  
  
  
  /* BLOG */
  .shadow {
    margin-bottom: 1rem;
    height: auto;
  }
  
  .shadow:hover {
   height: auto;
  }

    /* BLOG ENDS */
  
  
  
  /* FOOTER */
  footer {
    margin-top: 5rem;
  }
  .footer__container {
    grid-template-columns: 30rem 1fr;
    gap: 4rem;
  }

    /* FOOTER ENDS*/
  
  }

  