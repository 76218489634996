
.terms {
  padding: 2rem;
}

.terms h2 {
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: 900;
  color: var(--red);
}

.terms h4 {
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: 900;
  color: var(--red);
}

.legal-span {
  font-weight: 700;
}

.LastUpdated {
  font-weight: 700;
  border-top: 4px solid var(--black);
  color: var(--red);
}

  
  
  
  /* MEDIA QUERIES (small screens)  */
  @media screen and (max-width: 1024px) {
  }
  
  
  
  /* MEDIA QUERIES (small screens)  */
  @media screen and (max-width: 600px) {
 
    
  }
  
  
  
