.header {
    margin-top: 5rem;
    height: 43rem;
    overflow: hidden;
    border-bottom: 2px solid var(--color-gray-400);
}

.header__container {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    place-items: center;
    background: black;
}

.header__container-bg {
    position: absolute;
    width: 100%;
}

.header__container-bg img {
    margin-top: 14rem;
    opacity: 0.5;
}

.header-body {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
}

.header__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 3rem;
    width: 50%;
    margin: 0 auto;
    text-align: center;
    color: var(--color-gray-100);
}

.header__content .header-button {
    left: -9rem;
}

.header__content h1 {
    text-align: left;
    top: 2rem;
    font-size: 1.6rem;
    font-weight: 900;
}

.header-socials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
}

.header-socials h3 {
    font-size: 1.7rem;
    font-weight: 900;
    color: var(--light-bg);
}

.header-socials a {
    background: var(--red);
    padding: 0.5rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
}

.header-socials a:hover {
    border-color: var(--color-gray-100);
    background: rgb(187, 181, 181);
}

.header .h-svg {
    display: flex;
    gap: 2rem;
}

.header-socials a svg {
    color: var(--light-bg);
}



/* MEDIA QUERIES (small screens)  */
@media screen and (max-width: 599px) {
    .header {
        height: 30%;
    }

    .header__container-bg img {
        margin-top: 0;
        opacity: 0.5;
    }

    .header-body {
        width: 80%;
        gap: 2rem;
    }

    .header__content {
        align-items: center;
        gap: 2.5rem;
        width: 60%;
        margin: 0 auto;
        text-align: center;
        color: var(--color-gray-100);
    }

    .header__content h1 {
        text-align: center;
        top: 1.5rem;
        font-size: 12px;
    }

    .header__content .header-button {
        width: 89px;
        /* padding: 0.5rem; */
        font-size: 9px;
        left: -9rem;
    }

    .header-socials h3 {
        font-size: 12px;
    }

    .header .h-svg {
        display: flex;
        gap: 1rem;
    }

    .header-socials a svg {
       font-size: 12px;
    }
}


/* MEDIA QUERIES (medium screens)  */
@media screen and (min-width: 600px) and (max-width: 767px) {
    .header {
        margin-top: 5rem;
        height: 20rem;
        overflow: hidden;
        border-bottom: 2px solid var(--color-gray-400);
    }

    .header__container {
        width: 100%;
        height: 100%;
        position: relative;
        display: grid;
        place-items: center;
        background: black;
    }

    .header__container-bg img {
        margin: 0;
    }

    .header-body {
        width: 80%;
        gap: 2rem;
    }

    .header__content {
        align-items: center;
        gap: 2.5rem;
        width: 65%;
    }

    .header__content h1 {
        text-align: center;
        top: 2rem;
        font-size: 1.3rem;
    }

    .header-socials h3 {
        font-size: 1.3rem;
    }

    .header .h-svg {
        display: flex;
        gap: 1rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .header {
        margin-top: 5rem;
        height: 20rem;
        overflow: hidden;
        border-bottom: 2px solid var(--color-gray-400);
    }

    .header__container {
        width: 100%;
        height: 100%;
        position: relative;
        display: grid;
        place-items: center;
        background: black;
    }

    .header__container-bg img {
        margin: 0;
    }

    .header-body {
        width: 80%;
        gap: 2rem;
    }

    .header__content {
        align-items: center;
        gap: 2.5rem;
        width: 65%;
    }

    .header__content h1 {
        text-align: center;
        top: 2rem;
        font-size: 1.3rem;
    }

    .header-socials h3 {
        font-size: 1.3rem;
    }

    .header .h-svg {
        display: flex;
        gap: 1rem;
    }
}