Header {
    text-align: left;
}

Header h1 {
    position: relative;
    text-align: left;
    color: var(--light-bg);
}

Header p {
    position: relative;
    font-weight: 400;
    font-size: 1.2rem;
    text-align: left;
    left: -5rem;
}


.client__vision {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    /* background-color: var(--light-bk); */
}

.client__vision-container {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.client__section-image img {
    width: 500px;
}

.client__section-content {
    padding: 20px;
    background: rgb(255, 253, 253);
    border-width: 60%;
    box-shadow: 0px 0px 15px -5px;
    animation: ease-in;
    transition: var(--transition);
}

.client__section-content:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
    transition: var(--transition);
}

.client__section-content h2 {
    font-weight: 900;
    font-size: 1.3rem;
    color: var(--red);
}

.client__section-content p {
    margin: 1rem 0 1rem;
    font-weight: 400;
    color: var(--color-gray-500);
}


/* ####### benefit__vision ###### */
.benefit__vision {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85%;
    background-color: rgb(231, 168, 168);
}

.benefit__vision-container {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.benefit__section-image img {
    width: 500px;
}

.benefit__section-content {
    padding: 20px;
    background: rgb(255, 253, 253);
    border-width: 60%;
    box-shadow: 0px 0px 15px -5px;
    animation: ease-in;
    transition: var(--transition);
}

.benefit__section-content:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
    transition: var(--transition);
}

.benefit__section-content h2 {
    font-weight: 900;
    font-size: 1.3rem;
    color: var(--red);
}

.benefit__section-content p {
    margin: 1rem 0 3.5rem;
    font-weight: 400;
    color: var(--color-gray-500);
}



@media screen and (max-width: 345px) {
    .client__vision {
        height: 165%;
    }

    .client__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .client__section-image img {
        width: 300px;
    }

    .client__section-content:hover {
        transform: none;
    }


    /*** Benefits vision ***/
    .benefit__vision {
        height: 160%;
    }

    .benefit__vision-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .benefit__section-image img {
        width: 300px;
    }

    .benefit__section-content:hover {
        transform: none;
    } 
}


@media screen and (min-width: 350px) and (max-width: 390px) {
    .client__vision {
        height: 150%;
    }

    .client__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .client__section-image img {
        width: 350px;
    }

    .client__section-content:hover {
        transform: none;
    }


    /*** Benefits vision ***/
    .benefit__vision {
        height: 145%;
    }

    .benefit__vision-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .benefit__section-image img {
        width: 350px;
    }

    .benefit__section-content:hover {
        transform: none;
    } 
}


@media screen and (min-width: 395px) and (max-width: 430px) {
    .client__vision {
        height: 130%;
    }

    .client__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .client__section-image img {
        width: 400px;
    }

    .client__section-content:hover {
        transform: none;
    }


    /*** Benefits vision ***/
    .benefit__vision {
        height: 130%;
    }

    .benefit__vision-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .benefit__section-image img {
        width: 400px;
    }

    .benefit__section-content:hover {
        transform: none;
    }
}


@media screen and (min-width: 540px) and (max-width: 599px)  {
    .client__vision {
        height: 130%;
    }

    .client__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .client__section-content:hover {
        transform: none;
    }


    /*** Benefits vision ***/
    .benefit__vision {
        height: 120%;
    }

    .benefit__vision-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .benefit__section-content:hover {
        transform: none;
    }
}


@media screen and (min-width: 600px) and (max-width: 767px) {
    .client__vision {
        height: 130%;
    }

    .client__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .client__section-content:hover {
        transform: none;
    }


    /*** Benefits vision ***/
    .benefit__vision {
        height: 120%;
    }

    .benefit__vision-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .benefit__section-content:hover {
        transform: none;
    }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .client__vision {
        height: 140%;
    }

    .client__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .client__section-image img {
        width: 680px;
    }

    .client__section-content:hover {
        transform: none;
    }


    /*** Benefits vision ***/
    .benefit__vision {
        height: 130%;
    }

    .benefit__vision-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .benefit__section-content:hover {
        transform: none;
    }

    .benefit__section-image img {
        width: 680px;
    }
}