.dashbd {
    height: 5rem;
    width: 100%;
    background: var(--white);
    display: flex;
    place-items: center;
    position: fixed;
    gap: 2px;
    top: 0;
    left: 0;
    z-index: 50;
}

.nav-bar{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.nav-bar .logo{
    width: 100px;
}

.nav-bar ul {
    display: flex;
    list-style: none;
    justify-content: flex-end;
    gap: 2rem;
}


.nav-bar ul li {
    position: relative;
    display: flex;
    align-items: center;
    height: 5rem;
}

.nav-bar ul li span {
    color: var(--red);
}

.nav-bar ul li span:hover {
    color: rgb(34, 18, 18);
}

/*dropdown normal screen*/
.nav-bar ul li ul{
    display: none;
}

.nav-bar ul li:hover ul{
    width: 215px;
    height: 80px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    right: -5rem;
    gap: 0;
}

/*button*/
.nav__toggle-btn {
    display: none;
}

.nav__toggle-btn svg {
    color: var(--red);
}

.contactButton {
    margin-right: 5px;
}



/* For screens 1030px and above */
@media (min-width: 1030px) {
    .navList li:last-child span {
      display: inline-block;
      padding: 0.8rem;
      background-color: var(--red);
      color: var(--white) !important;
      border-radius: 3px;
      transition: var(--transition);
      font-weight: bold;
      text-align: center;
    }
  
    .navList li:last-child span:hover {
      background-color: var(--black);
      color: white;
    }
  }



@media screen and (max-width: 430px) {
    .nav__toggle-btn {
        display: inline-block;
        background: white;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .nav-bar{
        flex-wrap: nowrap;
    }

    .nav-bar .contactButton {
        display: none;
    }

    .nav__toggle-btn .menu h4 {
        display: none;
    }

    .nav-bar ul {
        position: absolute;
        flex-direction: column;
        background: var(--light-bk);
        width: 95%;
        top: 100%;
        right: -3rem;
        gap: 0;
        perspective: 400px;
        z-index: 10;
    }

   .show__Nav {
        display: flex;
        box-shadow: -2rem 2rem 5rem rgba(0, 0, 0blue, o.5);
        transform: translateX(0);
        transition: transform 800ms ease-in;
    }
    
   .hide__Nav {
        display: none;
        transform: translateX(200%);
        transition: transform 800ms ease-in-out;
    }

    .nav-bar ul li {
        width: 25%;
        height: 4rem;
    }

    .nav-bar ul li span {
        position: relative;
        color: var(--red);
        align-items: center;
        z-index: 10;
    }

/*animation*/
    .navList li {
        box-shadow: -2rem 2rem 5rem rgba(0, 0, 0blue, o.5);
        animation: navAnimation 600ms ease forwards;
        transform: rotateX(90deg);
        opacity: 0;
        right: 0;
        transform-origin: top;
    }

    .navList li:nth-child(2) {
        animation-delay: 200ms;
    }
    
    .navList li:nth-child(3) {
        animation-delay: 400ms;
    }
    
    .navList li:nth-child(4) {
        animation-delay: 600ms;
    }
    
    .navList li:nth-child(5) {
        animation-delay: 800ms;
    }
    
    .navList li:nth-child(6) {
        animation-delay: 1s;
    }
    
    .navList li:nth-child(7) {
        animation-delay: 1.2s;
    }
    
    @keyframes navAnimation {
        to {
            transform: rotate(0);
            opacity: 1;
        }
    }
/*animation ends*/


/*dropdown*/
    .nav-bar ul li:hover ul{
        width: 210px;
        height: 85px;
        top: 15px;
        right: -13.5rem;
        border: 1px dotted rgb(231, 168, 168);
    }

    .nav-bar ul li:hover ul li{
        width: 185px;
    }
}


@media screen and (min-width: 540px) and (max-width: 599px)  {
    .nav__toggle-btn {
        display: inline-block;
        background: white;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .nav-bar .contactButton {
        display: none;
    }

    .nav__toggle-btn .menu h4 {
        display: none;
    }

    .nav-bar{
        flex-wrap: nowrap;
    }

    .nav-bar ul {
        position: absolute;
        flex-direction: column;
        background: var(--light-bk);
        width: 80%;
        top: 100%;
        right: -3rem;
        gap: 0;
        perspective: 400px;
        z-index: 10;
    }

    .show__Nav {
        display: flex;
        transform: translateX(0);
        transition: transform 0.3s ease-in;
    }
    
    .hide__Nav {
        display: none;
        transform: translateX(200%);
        transition: transform 0.3s ease-in-out;
    }

    .nav-bar ul li {
        width: 25%;
        height: 4rem;
    }

    .nav-bar ul li span {
        color: var(--red);
        align-items: center;
        z-index: 10;
    }

/*animation*/
    .navList li {
        box-shadow: -2rem 2rem 5rem rgba(0, 0, 0blue, o.5);
        animation: navAnimation 600ms ease forwards;
        transform: rotateX(90deg);
        opacity: 0;
        right: 0;
        transform-origin: top;
    }

    .navList li:nth-child(2) {
        animation-delay: 200ms;
    }
    
    .navList li:nth-child(3) {
        animation-delay: 400ms;
    }
    
    .navList li:nth-child(4) {
        animation-delay: 600ms;
    }
    
    .navList li:nth-child(5) {
        animation-delay: 800ms;
    }
    
    .navList li:nth-child(6) {
        animation-delay: 1s;
    }
    
    .navList li:nth-child(7) {
        animation-delay: 1.2s;
    }
    
    @keyframes navAnimation {
        to {
            transform: rotate(0);
            opacity: 1;
        }
    }
/*animation ends*/

/*dropdown*/
    .nav-bar ul li:hover ul{
        width: 210px;
        height: 85px;
        top: 15px;
        right: -13rem;
        border: 1px dotted rgb(231, 168, 168);
    }

    .nav-bar ul li:hover ul li{
        width: 185px;
    }
}




@media screen and (min-width: 600px) and (max-width: 767px) {
    .nav__toggle-btn {
        display: inline-block;
        background: white;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .nav-bar .contactButton {
        display: none;
    }

    .nav__toggle-btn .menu h4 {
        display: none;
    }

    .nav-bar{
        flex-wrap: nowrap;
    }

    .nav-bar ul {
        position: absolute;
        flex-direction: column;
        background: var(--light-bk);
        width: 70%;
        top: 100%;
        right: -3rem;
        gap: 0;
        perspective: 400px;
        z-index: 10;
    }

    .show__Nav {
        display: flex;
        transform: translateX(0);
        transition: transform 0.3s ease-in;
    }
    
    .hide__Nav {
        display: none;
        transform: translateX(200%);
        transition: transform 0.3s ease-in-out;
    }

    .nav-bar ul li {
        width: 25%;
        height: 4rem;
    }

    .nav-bar ul li span {
        color: var(--red);
        align-items: center;
        z-index: 10;
    }

   /* dropdown*/
    .nav-bar ul li:hover ul{
        width: 210px;
        height: 90px;
        top: 15px;
        right: -13rem;
        border: 1px dotted rgb(231, 168, 168);
    }

    .nav-bar ul li:hover ul li{
        width: 185px;
    }
}



@media screen and (min-width: 768px) and (max-width: 1024px) {
    .nav__toggle-btn {
        display: inline-block;
        background: white;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .nav-bar .contactButton {
        display: none;
    }

    .nav__toggle-btn .menu h4 {
        position: absolute;
        display: flex;
        top: 30%;
        right: 75px;
        font-size: 1.5rem;
        color: var(--red);
    }

    .nav-bar{
        flex-wrap: nowrap;
    }

    .nav-bar ul {
        position: absolute;
        flex-direction: column;
        background: var(--light-bk);
        width: 60%;
        top: 100%;
        right: -3rem;
        gap: 0;
        perspective: 400px;
        z-index: 10;
    }

    .show__Nav {
        display: flex;
        transform: translateX(0);
        transition: transform 0.3s ease-in;
    }
    
    .hide__Nav {
        display: none;
        transform: translateX(200%);
        transition: transform 0.3s ease-in-out;
    }

    .nav-bar ul li {
        width: 25%;
        height: 4rem;
    }

    .nav-bar ul li span {
        color: var(--red);
        align-items: center;
        z-index: 10;
    }

    /*dropdown*/
    .nav-bar ul li:hover ul{
        width: 210px;
        height: 85px;
        top: 15px;
        right: -13rem;
        border: 1px dotted rgb(231, 168, 168);
    }

    .nav-bar ul li:hover ul li{
        width: 185px;
    }
}
