Header {
    text-align: left;
}

Header h2 {
    position: relative;
    text-align: left;
    left: -3rem;
}

Header p {
    position: relative;
    font-weight: 400;
    font-size: 1.2rem;
    text-align: left;
    left: -3rem;
}

.candidate__vision {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
    /* background-color: var(--light-bk); */
}

.candidate__vision-container {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.candidate__section-image img {
    width: 500px;
}

.candidate__section-content {
    padding: 20px;
    background: rgb(255, 253, 253);
    border-width: 60%;
    box-shadow: 0px 0px 15px -5px;
    animation: ease-in;
    transition: var(--transition);
}

.candidate__section-content:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
    transition: var(--transition);
}

.candidate__section-content h2 {
    font-weight: 900;
    font-size: 1.3rem;
    color: var(--red);
}

.candidate__section-content p {
    margin: 1rem 0 3.5rem;
    font-weight: 400;
    color: var(--color-gray-500);
}



/* BENEFITS */

.benefit__cvision {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    background-color: rgb(231, 168, 168);
}

.benefit__cvision-container {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.benefit__csection-image img {
    width: 500px;
}

.benefit__csection-content {
    padding: 20px;
    background: rgb(255, 253, 253);
    border-width: 60%;
    box-shadow: 0px 0px 15px -5px;
    animation: ease-in;
    transition: var(--transition);
}

.benefit__csection-content:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
    transition: var(--transition);
}

.benefit__csection-content h2 {
    font-weight: 900;
    font-size: 1.3rem;
    color: var(--red);
}

.benefit__csection-content p {
    margin: 1rem 0 3.5rem;
    font-weight: 400;
    color: var(--color-gray-500);

}

.benefits ul {
    padding-left: 3rem;
}

.benefits ul li {
    list-style: circle;
    margin-bottom: 1.2rem;
}



/* MEDIA QUERIES (small screens)  */
@media screen and (max-width: 345px) {
    .candidate__vision {
        height: 100%;
    }

    .candidate__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .candidate__section-image img {
        width: 300px;
    }

    .candidate__section-content:hover {
        transform: none;
    }

    .candidate__section-content h2 {
        font-weight: 900;
        font-size: 1rem;
        color: var(--red);
    }

    /* BENEFITS */
    .benefit__cvision {
        height: 170%;
    }

    .benefit__cvision-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .benefit__csection-image img {
        width: 300px;
    }

    .benefit__csection-content:hover {
        transform: none;
    }

    .benefit__csection-content h2 {
        font-weight: 900;
        font-size: 1rem;
        color: var(--red);
    }
}


@media screen and (min-width: 350px) and (max-width: 390px) {
    .candidate__vision {
        height: 100%;
    }

    .candidate__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .candidate__section-image img {
        width: 350px;
    }

    .candidate__section-content:hover {
        transform: none;
    }

    /* BENEFITS */
    .benefit__cvision {
        height: 175%;
    }

    .benefit__cvision-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .benefit__csection-image img {
        width: 350px;
    }

    .benefit__csection-content:hover {
        transform: none;
    }
}


@media screen and (min-width: 395px) and (max-width: 430px) {
    .candidate__vision {
        height: 110%;
    }

    .candidate__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .candidate__section-image img {
        width: 400px;
    }

    .candidate__section-content:hover {
        transform: none;
    }

    /* BENEFITS */
    .benefit__cvision {
        height: 157%;
    }

    .benefit__cvision-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .benefit__csection-image img {
        width: 400px;
    }

    .benefit__csection-content:hover {
        transform: none;
    }
}




@media screen and (min-width: 540px) and (max-width: 599px) {
    .candidate__vision {
        height: 110%;
    }

    .candidate__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .candidate__section-content:hover {
        transform: none;
    }

    /* BENEFITS */
    .benefit__cvision {
        height: 140%;
    }

    .benefit__cvision-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .benefit__csection-content:hover {
        transform: none;
    }
}




@media screen and (min-width: 600px) and (max-width: 767px) {
    .candidate__vision {
        height: 110%;
    }

    .candidate__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .candidate__section-content:hover {
        transform: none;
    }

    /* BENEFITS */
    .benefit__cvision {
        height: 140%;
    }

    .benefit__cvision-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .benefit__csection-content:hover {
        transform: none;
    }
}





/* MEDIA QUERIES (medium screens)  */
@media screen and (min-width: 768px) and (max-width: 1024px) {   
    .candidate__vision {
        height: 115%;
    }

    .candidate__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .candidate__section-content:hover {
        transform: none;
    }

    .candidate__section-image img {
        width: 680px;
    }

    /* BENEFITS */
    .benefit__cvision {
        height: 145%;
    }

    .benefit__cvision-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .benefit__csection-content:hover {
        transform: none;
    }

    .benefit__csection-image img {
        width: 680px;
    }
}

