/*#################################### Contact Us  ############################## */
.cont__container {
    background-image: url(../../images/nod8.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 84vh;
    margin-top: 5rem;
  }
  
  .cont {
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    margin-top: 50px;
    padding: 50px 0;
    color: #fff;
    height: 84vh;
  }
  
  .cont .right {
    position: relative;
    left: 0;
    top: 6rem;
  }
  
  .cont .box {
    width: 90%;
  }
  
  .cont .box .last {
    align-items: right;
  }
  
  .cont .right {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .cont .right h3 {
    font-weight: 600;
    font-size: 1.3rem;
    color: var(--red);
  }
  
  .cont .last ul li {
    display: flex;
    margin-top: 1.7rem;
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
  
  .cont .last ul li .addList {
    display: block;
  }
  
  .cont .last i {
    width: 60px;
    font-size: 20px;
    color: #1eb2a6;
  }
  
  .btn__card {
    display: flex;
    justify-content: center;
    background: none;
    margin-top: -4rem;
  }
  
  .btn__card button{
    width: 110;
    padding: 0.8rem;
    background-color: var(--red);
    border: 1px solid var(--black);
    transition: var(--transition);
    margin-bottom: 0.5rem;
    border-radius: 3px;
  }
  
  .btn__card button .lnk {
    text-transform: none;
    color: var(--white);
  }
  
  .btn__card button:hover {
    background: var(--black);
  }
  
  .btn__card button:hover .lnk {
    color: var(--white);
  }
  


/* MEDIA QUERIES (small screens)  */
@media screen and (max-width: 430px) {
    /* MAIN CONTACT */
.cont__container {
    height: 60vh;
    margin-top: 5rem;
  }
  
  .cont {
    padding: 20px 0;
    height: 60vh;
  }
  
  .cont .row {
    width: 100%;
  }
  
  .cont .right {
    padding-right: 0;
    top: auto;
    left: 5%;
  }
  
  .cont .container .box .right h3 {
    font-weight: 900;
    font-size: 2rem;
    color: var(--red);
  }
  
  
  .cont .last ul li {
    margin-bottom: 7px;
    font-size: 0.8rem;
    gap: 2rem;
  }
  
  .cont .last ul li .addList p {
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
  }
  
  .cont .last i {
    width: 60px;
    font-size: 15px;
  }
  
  /* MAIN CONTACT ENDS */
}

@media screen and (min-width: 540px) and (max-width: 599px)  {
    /* MAIN CONTACT */
.cont__container {
    height: 70vh;
    margin-top: 5rem;
  }
  
  .cont {
    padding: 20px 0;
    height: 70vh;
  }
  
  .cont .row {
    width: 100%;
  }
  
  .cont .right {
    padding-right: 0;
    left: 5%;
  }
  
  .cont .last ul li {
    margin-bottom: 7px;
    font-size: 0.8rem;
    gap: 2rem;
  }
  
  .cont .last ul li .addList p {
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
  }
  
  .cont .last i {
    width: 60px;
    font-size: 15px;
  }
  
  /* MAIN CONTACT ENDS */
}

@media screen and (min-width: 600px) and (max-width: 767px) {
    /* MAIN CONTACT */
.cont__container {
    height: 74vh;
    margin-top: 5rem;
  }
  
  .cont {
    padding: 20px 0;
    height: 74vh;
  }
  
  .cont .row {
    width: 100%;
  }
  
  .cont .right {
    padding-right: 0;
    left: 5%;
  }
  
  .cont .right h3 {
    font-size: 1rem;
  }
  
  .cont .last ul li {
    margin-bottom: 7px;
    font-size: 0.8rem;
    gap: 2rem;
  }
  
  .cont .last ul li .addList p {
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
  }
  
  .cont .last i {
    width: 60px;
    font-size: 15px;
  }
  
  /* MAIN CONTACT ENDS */
}

/* MEDIA QUERIES (medium screens)  */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    /* CONTACT */
  .cont__container {
    height: 64vh;
    margin-top: 10rem;
    width: 100%;
  }

  .cont {
    height: 64vh;
    width: 100%;
  }

  .cont .flexSB {
    max-width: 720px;
  }
  
  .cont .right {
    top: -2rem;
  }
  
  .cont .last ul li {
    font-size: 1.1rem;
  }

    /* CONTACT ENDS */
}