.about__header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  background-color: rgb(231, 168, 168);
  z-index: -1;
}

.main__about-container {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.main__about-image img {
  width: 500px;
}

.about__left-content {
  padding: 20px;
  background: rgb(255, 253, 253);
  border-width: 60%;
  box-shadow: 0px 0px 15px -5px;
  animation: ease-in;
  transition: var(--transition);
}

.about__left-content:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 15px 0px;
  transition: var(--transition);
}

.about__left-content h3 {
  font-weight: 900;
  font-size: 1.3rem;
  color: var(--red);
}

.about__left-content p {
  margin: 1rem 0 3.5rem;
  font-weight: 400;
  color: var(--color-gray-500);
}



@media screen and (max-width: 345px) {
  .about__header {
    height: 130%;
  }

  .main__about-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }

  .main__about-image img {
    width: 300px;
  }

  .about__left-content:hover {
    transform: none;
  }
}


@media screen and (min-width: 350px) and (max-width: 390px) {
  .about__header {
    height: 130%;
  }

  .main__about-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }

  .main__about-image img {
    width: 350px;
  }

  .about__left-content:hover {
    transform: none;
  }
}


@media screen and (min-width: 395px) and (max-width: 430px) {
  .about__header {
    height: 135%;
  }

  .main__about-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }

  .main__about-image img {
    width: 400px;
  }

  .about__left-content:hover {
    transform: none;
  }
}

@media screen and (min-width: 540px) and (max-width: 599px)  {
  .about__header {
    height: 122%;
  }

  .main__about-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }

  .main__about-image img {
    width: 500px;
  }


  .about__left-content:hover {
    transform: none;
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .about__header {
    height: 120%;
  }

  .main__about-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }

  .about__left-content:hover {
    transform: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about__header {
    height: 125%;
  }

  .main__about-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }

  .about__left-content:hover {
    transform: none;
  }

  .main__about-image img {
    width: 680px;
  }
}