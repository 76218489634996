.notfound {
    background: var(--color-gray-500);    
}

.notfound__container {
    text-align: center;
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.notfound__container .btn {
    margin-top: 2rem;
    display: inline-block;
}
