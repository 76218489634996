.o-hub {
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.o-hub__wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}

.o-hub__content {
  position: relative;
  text-align: center;
  width: 50%;
  padding: 20px;
  background-color: var(--light-bk);
}

.o-hub__content h2 {
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: 900;
  color: var(--red);
}

.o-hub__content p {
  font-size: 1.2rem;
  margin-bottom: 3rem;
}



/* MEDIA QUERIES (small screens)  */
@media screen and (max-width: 1024px) {
  .o-hub {
    height: 50%;
  }

  .o-hub__content {
    width: 100%;
  }
}



/* MEDIA QUERIES (small screens)  */
@media screen and (max-width: 600px) {

}