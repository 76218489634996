.care-support {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;
    /* background-color: var(--light-bk); */
}

.cs-container {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.main-cs-image img {
    width: 500px;
} 

.cs-content {
    padding: 20px;
    background: rgb(255, 253, 253);
    border-width: 60%;
    box-shadow: 0px 0px 15px -5px;
    animation: ease-in;
    transition: var(--transition);
}

.cs-content:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
    transition: var(--transition);
}

.cs-content h3 {
    font-weight: 900;
    font-size: 1.3rem;
    color: var(--red);
}

.cs-content p {
    margin: 1rem 0 3.5rem;
    font-weight: 400;
    color: var(--color-gray-500);
}


@media screen and (max-width: 345px) {
    .care-support {
        height: 105%;
    }

    .cs-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .main-cs-image img {
        width: 300px;
    } 

    .cs-content:hover {
        transform: none;
    }
}


@media screen and (min-width: 350px) and (max-width: 390px) {
    .care-support {
        height: 105%;
    }

    .cs-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .main-cs-image img {
        width: 350px;
    } 

    .cs-content:hover {
        transform: none;
    }
}


@media screen and (min-width: 395px) and (max-width: 430px) {
    .care-support {
        height: 105%;
    }

    .cs-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .main-cs-image img {
        width: 400px;
    } 

    .cs-content:hover {
        transform: none;
    }
}


@media screen and (min-width: 540px) and (max-width: 599px)  {
    .care-support {
        height: 108%;
    }

    .cs-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .main-cs-image img {
        width: 500px;
    } 
    
    .cs-content:hover {
        transform: none;
    }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
    .care-support {
        height: 105%;
    }

    .cs-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .main-cs-image img {
        width: 500px;
    } 
    
    .cs-content:hover {
        transform: none;
    }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .care-support {
        height: 115%;
    }

    .cs-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .main-cs-image img {
        width: 680px;
    } 
    
    .cs-content:hover {
        transform: none;
    }
}