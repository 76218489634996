.services-submenu {
    width: 140px;
    height: 4rem;
    position: absolute;
    top: 80px;
    right: 30rem;
    list-style: none;
    text-align: start;
    align-items: center;
    justify-content: center;
    z-index: 99;
    background-color: var(--light-bk);
}

.services-submenu li {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.services-submenu.clicked {
    display: none;
}

.submenu-item {
    display: block;
    width: 100%;
    text-decoration: none;
    color: var(--red) !important;
}

.submenu-item:hover {
    color: var(--black) !important;
}

.services-submenu li {
    width: 100%;
    box-shadow: -2rem 2rem 5rem rgba(0, 0, 0blue, o.5);
    animation: navAnimation 600ms ease forwards;
    transform: rotateX(90deg);
    opacity: 0;
    transform-origin: top;
}

.services-submenu li:nth-child(1) {
    animation-delay: 200ms;
}

.services-submenu li:nth-child(2) {
    animation-delay: 400ms;
}

@keyframes navAnimation {
    to {
        transform: rotate(0);
        opacity: 1;
    }
}





@media screen and (max-width: 430px) {
    .services-submenu {
        position: absolute;
        top: 4rem;
        right: 15rem;
        list-style: none;
        text-align: start;
        z-index: 10;
    }
}


@media screen and (min-width: 540px) and (max-width: 599px)  {
    .services-submenu {
        position: relative;
        top: 4rem;
        right: 0;
        list-style: none;
        text-align: start;
    }
}


@media screen and (min-width: 600px) and (max-width: 767px) {
    .services-submenu {
        position: absolute;
        top: 3px;
        right: 18.4rem;
        list-style: none;
        text-align: start;
        z-index: 99;
    }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .services-submenu {
        position: absolute;
        top: 3px;
        right: 25rem;
        list-style: none;
        text-align: start;
        z-index: 99;
    }
}