/*visiting-care*/
.services {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110%;
    /* background-color: var(--light-bk); */
}

.services-cointainer {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.services_section-image img {
    width: 500px;
}

.services-cointainer .services_section-content {
    width: 60%;
    padding: 20px;
    background: rgb(255, 253, 253);
    border-width: 60%;
    box-shadow: 0px 0px 15px -5px;
    animation: ease-in;
    transition: var(--transition);
}

.services_section-content:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
    transition: var(--transition);
}

.services_section-content h3 {
    font-weight: 900;
    font-size: 1.3rem;
    color: var(--red);
}

/*livein-care*/
.services-liveIn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: rgb(231, 168, 168);
}

.cservices-cointainer {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
}

.servicesLiveIn_section-image img {
    width: 500px;
}

.cservices-cointainer .servicesLiveIn_section-content {
    width: 60%;
    padding: 20px;
    background: rgb(255, 253, 253);
    border-width: 60%;
    box-shadow: 0px 0px 15px -5px;
    animation: ease-in;
    transition: var(--transition);
}

.servicesLiveIn_section-content:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
    transition: var(--transition);
}

.servicesLiveIn_section-content h3 {
    font-weight: 900;
    font-size: 1.3rem;
    color: var(--red);
}



/* MEDIA QUERIES (small screens)  */
@media screen and (max-width: 345px) {    
    .services {
        height: 205%;
    }

    .services-cointainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .services_section-image img {
        width: 300px;
    }

    .services-cointainer .services_section-content {
        width: 100%;
    }

    .services_section-content:hover {
        transform: none;
    }   


    /*livein-care*/
    .services-liveIn {
        height: 195%;
    }

    .cservices-cointainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .servicesLiveIn_section-image img {
        width: 300px;
    }

    .cservices-cointainer .servicesLiveIn_section-content {
        width: 100%;
    }

    .servicesLiveIn_section-content:hover {
        transform: none;
    }    
}


@media screen and (min-width: 350px) and (max-width: 390px) {
    .services {
        height: 180%;
    }
    
    .services-cointainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    
    .services_section-image img {
        width: 350px;
    }

    .services-cointainer .services_section-content {
        width: 100%;
    }
    
    .services_section-content:hover {
        transform: none;
    }   

    
    /*livein-care*/
    .services-liveIn {
        height: 180%;
    }
    
    .cservices-cointainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    
    .servicesLiveIn_section-image img {
        width: 350px;
    }
    
    .cservices-cointainer .servicesLiveIn_section-content {
        width: 100%;
    }
    
    .servicesLiveIn_section-content:hover {
        transform: none;
    }    
}


@media screen and (min-width: 395px) and (max-width: 430px) {
    .services {
        height: 175%;
    }
    
    .services-cointainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    
    .services_section-image img {
        width: 400px;
    }

    .services-cointainer .services_section-content {
        width: 100%;
    }
    
    .services_section-content:hover {
        transform: none;
    }   

    
    /*livein-care*/
    .services-liveIn {
        height: 163%;
    }
    
    .cservices-cointainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    
    .servicesLiveIn_section-image img {
        width: 400px;
    }
    
    .cservices-cointainer .servicesLiveIn_section-content {
        width: 100%;
    }
    
    .servicesLiveIn_section-content:hover {
        transform: none;
    }    
}



@media screen and (min-width: 540px) and (max-width: 599px) {
    .services {
        height: 160%;
    }
    
    .services-cointainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .services-cointainer .services_section-content {
        width: 100%;
    }
    
    .services_section-content:hover {
        transform: none;
    }   

    
    /*livein-care*/
    .services-liveIn {
        height: 140%;
    }
    
    .cservices-cointainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    
    .cservices-cointainer .servicesLiveIn_section-content {
        width: 100%;
    }
    
    .servicesLiveIn_section-content:hover {
        transform: none;
    }    
}

@media screen and (min-width: 600px) and (max-width: 767px) {
    .services {
        height: 160%;
    }
    
    .services-cointainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .services-cointainer .services_section-content {
        width: 100%;
    }
    
    .services_section-content:hover {
        transform: none;
    }   

    
    /*livein-care*/
    .services-liveIn {
        height: 140%;
    }
    
    .cservices-cointainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    
    .cservices-cointainer .servicesLiveIn_section-content {
        width: 100%;
    }
    
    .servicesLiveIn_section-content:hover {
        transform: none;
    }    
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .services {
        height: 150%;
    }
    
    .services-cointainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .services-cointainer .services_section-content {
        width: 100%;
    }

    .services_section-image img {
        width: 680px;
    }
    
    .services_section-content:hover {
        transform: none;
    }   

    
    /*livein-care*/
    .services-liveIn {
        height: 140%;
    }
    
    .cservices-cointainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    
    .cservices-cointainer .servicesLiveIn_section-content {
        width: 100%;
    }

    .servicesLiveIn_section-image img {
        width: 680px;
    }
    
    .servicesLiveIn_section-content:hover {
        transform: none;
    }    
}