.about__story {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    /* background-color: var(--light-bk); */
}

.about__story-container {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.about__section-image img {
    width: 500px;
}


.about__section-content {
    padding: 20px;
    background: rgb(255, 253, 253);
    border-width: 60%;
    box-shadow: 0px 0px 15px -5px;
    animation: ease-in;
    transition: var(--transition);
}

.about__section-content:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
    transition: var(--transition);
}

.about__section-content h2 {
    font-weight: 900;
    font-size: 1.3rem;
    color: var(--red);
}

.about__section-content p {
    margin: 1rem 0 3.5rem;
    font-weight: 400;
    color: var(--color-gray-500);
}


/*************ABOUT VISION*************/
.about__vision {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 105%;
    background-color: rgb(231, 168, 168);
}

.about__vision-container {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.about__vsection-image img {
    width: 500px;
}

.about__vsection-content {
    padding: 20px;
    background: rgb(255, 253, 253);
    border-width: 60%;
    box-shadow: 0px 0px 15px -5px;
    animation: ease-in;
    transition: var(--transition);
}

.about__vsection-content:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
    transition: var(--transition);
}

.about__vsection-content h2 {
    font-weight: 900;
    font-size: 1.3rem;
    color: var(--red);
}

.about__vsection-content p {
    margin: 1rem 0 1rem;
    font-weight: 400;
    color: var(--color-gray-500);
}

.bttn__card {
    gap: 25px;
}







/* MEDIA QUERIES (small screens)  */
@media screen and (max-width: 345px) {
    .about__story {
        height: 130%;
    }

    .about__story-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    
    .about__section-image img {
        width: 300px;
    }

    .about__section-content:hover {
        transform: none;
    }


    /******ABOUT VISION********/
    .about__vision {
        height: 197%;
    }

    .about__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .about__vsection-image img {
        width: 300px;
    }

    .about__vsection-content p {
        margin: 1rem 0 1rem;
    }

    .about__vsection-content:hover {
        transform: none;
    }
}


@media screen and (min-width: 350px) and (max-width: 390px) {
    .about__story {
        height: 115%;
    }

    .about__story-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    
    .about__section-image img {
        width: 350px;
    }

    .about__section-content:hover {
        transform: none;
    }


    /******ABOUT VISION********/
    .about__vision {
        height: 180%;
    }

    .about__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .about__vsection-image img {
        width: 350px;
    }

    .about__vsection-content p {
        margin: 1rem 0 1rem;
    }

    .about__vsection-content:hover {
        transform: none;
    }
}



@media screen and (min-width: 395px) and (max-width: 430px) {
    .about__story {
        height: 110%;
    }

    .about__story-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    
    .about__section-image img {
        width: 400px;
    }

    .about__section-content:hover {
        transform: none;
    }


    /******ABOUT VISION********/
    .about__vision {
        height: 170%;
    }

    .about__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .about__vsection-image img {
        width: 400px;
    }

    .about__vsection-content p {
        margin: 1rem 0 1rem;
    }

    .about__vsection-content:hover {
        transform: none;
    }
}



@media screen and (min-width: 540px) and (max-width: 599px) {
    .about__story {
        height: 115%;
    }

    .about__story-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .about__section-content:hover {
        transform: none;
    }


    /******ABOUT VISION********/
    .about__vision {
        height: 145%;
    }

    .about__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .about__vsection-content p {
        margin: 1rem 0 1rem;
    }

    .about__vsection-content:hover {
        transform: none;
    }
}



@media screen and (min-width: 600px) and (max-width: 767px) {
    .about__story {
        height: 115%;
    }

    .about__story-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .about__section-content:hover {
        transform: none;
    }


    /******ABOUT VISION********/
    .about__vision {
        height: 160%;
    }

    .about__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .about__vsection-content p {
        margin: 1rem 0 3rem;
    }

    .about__vsection-content:hover {
        transform: none;
    }
}




/* MEDIA QUERIES (medium screens)  */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .about__story {
        height: 120%;
    }

    .about__story-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .about__section-content:hover {
        transform: none;
    }

    .about__section-image img {
        width: 680px;
    }


    /******ABOUT VISION********/
    .about__vision {
        height: 150%;
    }

    .about__vision-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .about__vsection-content p {
        margin: 1rem 0 3rem;
    }

    .about__vsection-content:hover {
        transform: none;
    }

    .about__vsection-image img {
        width: 680px;
    }
}

