.card__container {
    height: 42vh;
    width: 600px;
    overflow: hidden;
    padding: 20px;
    background: rgb(255, 253, 253);
    border-width: 60%;
    box-shadow: 0px 0px 15px -5px;
    animation: ease-in;
    transition: var(--transition);
}

.card__container:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
}


.card__content {
    margin: 1rem;
    margin-top: 0.5rem;
}

h3,p {
    /* Resetting default value*/
    margin: 0;
    padding: 0;
}

.card__title {
    margin-bottom: 0.5rem;
}

.card__title h3 {
    font-weight: 900;
    font-size: 1.3rem;
    color: var(--red);
}

.card__body p {
    color: var(--color-gray-500);
}

.bttn__card {
    display: flex;
    justify-content: center;
    background: none;
}

.bttn__card button{
    padding: 0.8rem;
    background-color: var(--red);
    border: none;
    transition: var(--transition);
    margin-bottom: 0.5rem;
    border-radius: 3px;
}

.bttn__card button:hover {
    background: var(--black);
}

.lnk {
    text-transform: none;
    text-decoration: none;
    font-weight: bold;
    color: var(--white);
}




/* MEDIA QUERIES (small screens)  */
@media screen and (max-width: 430px) {
    .card__container {
        height: 40%;
        width: 100%;
        position: relative;
    }
    
    .card__container:hover {
        transform: none;
        box-shadow: 0px 0px 15px 0px;
    }
    
    .bttn__card button{
        z-index: 10;
    }
}


@media screen and (min-width: 430px) and (max-width: 599px) {
    .card__container {
    height: 40%;
    width: 100%;
    position: relative;
}

.card__container:hover {
    transform: none;
    box-shadow: 0px 0px 15px 0px;
}

.bttn__card button{
    z-index: 10;
}

}

/* MEDIA QUERIES (medium screens)  */
@media screen and (min-width: 600px) and (max-width: 767px) {
    .card__container {
        height: 38%;
        width: 100%;

    }
}



@media screen and (min-width: 768px) and (max-width: 1024px) {
    .card__container {
        height: 30%;
        width: 100%;
    }
}



